<template>
  <div>
    <TopContFaq></TopContFaq>
  </div>
</template>

<script>
import TopContFaq from "../../components/web/faq/TopContFaq";
export default {
  name: "FAQ",
  components: { TopContFaq }
};
</script>

<style scoped></style>
