<template>
  <div class="root_cont">
    <div class="position-relative">
      <FaqGridAnimation></FaqGridAnimation>

      <div class="t_cont">
        <div class="blue_bg">
          <div class="container">
            <div class="t_text">
              <h2>
                <span>{{ t.faq }}</span>
              </h2>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="yellow_bg pb-5">
      <div class="container pt-40">
        <div class="">
          <template v-for="(d, i) in faq_content">
            <div class="acc_card">
              <div class="acc_head row" @click="accToggle($event, d)">
                <div class="col-10">
                  <h5><span v-html="d.h"></span></h5>
                </div>
                <div class="side_arrow_cont col-2">
                  <img
                    src="/static/images/Arrow_blue.svg"
                    alt=""
                    class="side_arrow"
                    :class="{ active: d.active }"
                  />
                </div>
              </div>
              <div class="acc_body" :class="{ active: d.active }">
                <div class="acc_body_content">
                  <template v-if="d.html">
                    <div v-html="d.html"></div>
                  </template>
                  <template v-else>
                    <p v-for="p in d.p" v-html="p"></p>
                  </template>
                </div>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
const t =
  //<i18n>
{
  "en": {
    "faq": "FAQ",
    "q_1_h": "How big of a problem is land ownership in Croatia?",
    "q_1_p_1": "It's a significant problem. There are vast quantities of land in Croatia with no clear land title.",
    "q_1_p_2": "Without clear title, that land is effectively worthless as it prohibits legitimate use. This is bad for potential owners of land looking to realise value from their asset. It is also bad for the country as it acts as a barrier to the many billions of Euros of inward investment other countries in the Mediterranean thrive on.",
    "q_1_p_3": "We estimate that by solving these issues, we can unlock anywhere between €5bn and €10bn in value providing income for owners, and a substantial boost to the economy supporting jobs and growth.",
    "q_2_h": "Why do these issues exist?",
    "q_2_p_1": "These issues do not exist solely in Croatia but they are particularly prevalent here. They arise in countries that have experienced political and revolutionary turmoil resulting in mass migration combined with traditional attitudes on gender inequality now wholly incompatible with existing EU and international law.",
    "q_2_p_2": "Specific issues arise due to:",
    "q_2_l_1": "The inability to trace owners, many of whom have left the country.",
    "q_2_l_2": "Inconsistencies across different land registries.",
    "q_2_l_3": "Multiple owners of land unable to agree their allocation.",
    "q_2_l_4": "Difficulty in tracing relatives of deceased owners.",
    "q_2_l_5": "The complexity and cost of navigating the legal system to establish clean title.",
    "q_3_h": "How have you solved these issues?",
    "q_3_p_1": "Technology, Process Automation and three years of intensive Research and Development. Terra Adriatica is a technology-led company underpinned by an exhaustive review of Croatian law to establish, where an individual has a valid claim, an inexorable route to establishing clean title to land ahead of its sale – no matter the complication. Our claim is fully backed by legal opinion.",
    "q_3_p_2": "Our use of the latest, advanced technology is a game-changer. We apply this technology to solve the myriad of highly complex problems that are typical to establishing clean title. Please visit our <a href='/en/technology'>Technology Section</a> for a more in-depth explanation of the methods we use.",
    "q_4_h": "What can you do for me?",
    "q_4_p_1": "If you have a valid claim to land and if you wish to realise value from the sale of that land at no upfront cost to yourself, then we can help.",
    "q_5_h": "What can't you do for me?",
    "q_5_p_1": "Our business model relies upon generating income from the eventual sale of land. The process to establish clean title is intensive and costly with that cost covered by external investors willing to lend based on our assessment. Without a sale at the end of the process, we would be unable to cover those costs. Accordingly, we are currently unable to offer a stand-alone service to establish clean title.",
    "q_5_p_2": "For those interested solely in establishing clean title to land or for anyone simply wishing to understand in more detail what potential claim to land they may have, we offer optional paid-for reports to registered users.",
    "q_5_p_3": "Please visit our <a target='_blank' href='/solutions#solutions_reports_table'>Solutions</a> Section for more details.",
    "q_6_h": "How accurate is your initial estimation to the land I may own?",
    "q_6_p_1": "With your permission and using the information you submit, we correlate that data with tens of millions of public records across multiple, diverse public sources including the Land Registry and the Cadastre. ",
    "q_6_p_2": "Using our AI platform, we then analyse those results, taking into account a multitude of factors that may influence them such as the variation of names over the years and miss-spellings in the registries to give us an indication of what you might own and where. Each result is then examined so that we can assess internally the length of time it would take to achieve title ahead of a sale with as high a level of certainty as possible.",
    "q_6_p_3": "There are many imperfections in the land registry such as the issue of non-existent records or wholly inaccurate records. We often come across instances where an individual has inherited land but that right to land has not been entered into the public record. Our Locksmith process addresses each of these, providing an inexorable process to establish clean title and sale.",
    "q_6_p_4": "Establishing clean title to land is a long and complex process. Beware of anyone telling you differently.",
    "q_7_h": "Am I guaranteed that you will take my case?",
    "q_7_p_1": "We are in part able to do what we do because of economies of scale. In order to make the process viable, we may need to consolidate diverse land parcels within communities. Once we reach critical mass, we are then able to start the process and incur the cost of doing so.",
    "q_7_p_2": "Initially, therefore, we ask you to agree to joining our register of interest. Once you have done that, we get to work to establish critical mass for that particular community project so that we can move to the next stage of establishing clean title ahead, ultimately of the sale of that land.",
    "q_7_p_3": "Even if you have land categorised as a low probability, as long as you have an account with us, we keep your details on file should the situation change - such as an update to the registry which changes your status. In that event, we will contact you again to let you know of your options.",
    "q_8_h": "I am one of many owners of the same plot - how does that work?",
    "q_8_p_1": "Each co-owner of land has the right to initiate proceedings to establish clean title over the entire parcel of land to which they are a party. We use these procedures and various methods to track and engage with other owners to accelerate the process. However long the process takes, it will be achieved. It is inexorable.",
    "q_9_h": "How long will it take to sell my land?",
    "q_9_p_1": "There are many factors affecting the time that the process will take. These include - as mentioned in the above answer - reaching a critical mass of land parcels within any particular community project so as to ensure the cost viability of that project.",
    "q_9_p_2": "Once we are ready to move forward and have funding secured, we aim to establish clean title within 3-4 years. Once clean title has been established, we work with all parties to maximise the value of that land ahead of its sale - a process we expect between 12 and 24 months to achieve.",
    "q_10_h": "How can you achieve all this at no upfront cost?",
    "q_10_p_1": "Investors are finding it increasingly difficult in today's economy to generate sustainable returns on investment. This presents all parties to a Locksmith Agreement with a unique opportunity.",
    "q_10_p_2": "We match investors seeking asset-backed yields with sellers looking to realise value from their land.",
    "q_10_p_3": "Terra Adriatica will be able to demonstrate to investors its ability to secure clean title ahead of sale and investors will in return fund the process and guarantee the seller an Initial Installment when clean title is established. Once the land is sold, you as the seller will receive a final payment which when combined with the Initial Installment will equate to a minimum of a net 80% of the total value achieved.",
    "q_11_h": "Who decides how much the land is worth?",
    "q_11_p_1": "Ultimately, the market will decide. Our Locksmith product is specifically designed to ensure that all parties - you (as the seller), Terra Adriatica and the Investor are all fully incentivized and therefore aligned to secure the highest possible value at sale.",
    "q_11_p_2": "We will work with full transparency to achieve the maximum possible price. We do this in a number of ways including aggregating your land with surrounding plots to establish a larger area more likely to be of interest to a developer. We will also work (where possible) to apply for a change in zoning of that land from say agricultural to building to support inward investment - which we would expect local government to support. ",
    "q_12_h": "What guarantees do I have?",
    "q_12_p_1": "As part of the Locksmith agreement, the investor will guarantee you an Initial Installment when clean title is established. This Initial Installment will reflect the risk that the investor is willing to take and will therefore be significantly less than the expected price at sale.",
    "q_12_p_2": "Once clean title has been established, we will work to maximise the value of that land as previously explained. This process will take 1-2 years depending on the complexity. Once sold and per the contract, you will then receive a further payment bringing your entire share from the overall proceeds to at least 80% of the net proceeds from sale.",
    "q_13_h": "What if I change my mind about selling the land?",
    "q_13_p_1": "Once you enter into a Locksmith agreement - you are committed to selling the land as this is how each party within the Locksmith contract achieves a return on their respective investment in facilitating the process.",
    "q_14_h": "If I agree to enter into a Locksmith Agreement, what are my commitments?",
    "q_14_p_1": "Once you enter into a Locksmith agreement, you will commit to providing us with information if, as and when we need it as well as providing the land as collateral when clean title has been achieved - in return for which you, as the Seller, receive an initial installment ahead of the sale of the land and your share of those proceeds.",
    "q_15_h": "How does Terra Adriatica make money?",
    "q_15_p_1": "The only time we make money from a Locksmith Agreement is when the land is sold - at which point we take 10% of the net proceeds from sale as a fee for our services.",
    "q_15_p_2": "Separately to this, we also make available optional reports for purchase at any time outlining what potential rights to land we believe you may have. These reports vary in terms of detail. Please view our <a href='/en/solutions'>Solutions Page</a> for more information.",
    "q_16_h": "Why would I use Terra Adriatica?",
    "q_16_p_1": "Ultimately, you would use our services if you wish to realise value from the sale of land to which you may have rights.",
    "q_16_p_2": "Achieving this by yourself is possible given unlimited time and money. If you choose to do so then we can provide you with access to our paid reports to help that considerable effort. We are confident, however, that ultimately we will prove to be the most effective partner for you.",
    "q_16_p_3": "Even if we deem the probability to you having rights to be low - you may wish to keep your information with us at zero obligation - so that we can contact you at a later date should new information come to light. Databases are constantly being updated, so your prospects may change.",
    "q_17_h": "What is the Initial Instalment?",
    "q_17_p_1": "The Initial Instalment is paid by the Investor to the Seller and provides the mechanism by which all cleaned land in a given project is consolidated into one legal entity.",
    "q_17_p_2": "This mechanism is necessary to facilitate an efficient sale as different bits of land with perhaps hundreds of owners needing hundreds of contracts/signatures/negotiations would be difficult to impossible to sell.",
    "q_17_p_3": "The Investor secures this payment only on the land and has no right to reclaim the money spent if for whatever reason the land cannot be sold, however all sellers retain their rights to their share of the final sale proceeds after they have received their instalment.",
    "q_17_p_4": "Because the Investor is taking all the risk on the land being sold, the Initial Instalment will be a small percentage of the hoped-for price achieved at sale – most likely around 5%.",
    "q_17_p_5": "The Investor will be legally contracted and supervised by Terra Adriatica to sell the land for the highest possible price. The Investor will then recoup his costs plus interest as well as take an incentive fee of 2% of the difference between the final sale price and the instalment.",
    "q_17_p_6": "The Investor’s incentive fee ensures that all parties align in achieving the highest possible price at sale.",
    "q_17_p_7": "The remaining sum (the Net Proceeds) will then be returned 90% to the Seller and 10% to Terra Adriatica.",
    "q_18_h": "How much will I get from the sale of my land?",
    "q_18_p_1": "You will receive 90% of the Net Proceeds from the sale of the land. Together, with the Initial Instalment, we realistically expect this to equate to more than 80% of the price achieved at sale.",
    "q_18_p_2": "Net Proceeds is defined as the balance of funds available from the final sale of the land after deduction of all financing fees and associated costs.",
    "q_18_p_3": "It is important to note, that while the Investor’s financing and 2% incentive fees are known, the associated costs of cleaning the land (e.g. legal fees and survey fees etc.) are inevitably variable depending on the complexity of the task.",
    "q_18_p_4": "We don’t, however, anticipate these associated costs to impact the Net Proceeds figure significantly – we estimate that impact to be between 2% - 5% of the final sale price.",
    "q_18_p_5": "The below illustration assumes that the land sells for €100K and that the associated costs will equate to 4% of that sale price – which is at the higher end of our expectation.",
    "q_18_g_1_1": "Price Achieved at Sale:",
    "q_18_g_1_2": "€ 100.000",
    "q_18_g_2_1": "Less:",
    "q_18_g_3_1": "Variable Cost Assumption",
    "q_18_g_3_2": "- € 4.000",
    "q_18_g_4_1": "Initial Instalment (already paid to Seller) Recouped",
    "q_18_g_4_2": "- € 5.000",
    "q_18_g_5_1": "Interest payable to Investor *",
    "q_18_g_5_2": "- € 1.800",
    "q_18_g_6_1": "Investor 2% Incentive Fee.",
    "q_18_g_6_2": "- € 1.900",
    "q_18_g_7_1": "Net Proceeds from sale:",
    "q_18_g_7_2": "€ 87.300",
    "q_18_g_8_1": "Terra Adriatica's 10% Share Of Net Proceeds",
    "q_18_g_8_2": "- € 8.730",
    "q_18_g_9_1": "Total Income:",
    "q_18_g_10_1": "Initial Instalment **",
    "q_18_g_10_2": "€ 5.000",
    "q_18_g_11_1": "Final Settlement *** (90% Share Of Net Proceeds)",
    "q_18_g_11_2": "€ 78.570",
    "q_18_g_12_1": "Sellers Total Income:",
    "q_18_g_12_2": "€ 83.570",
    "q_18_g_13_1": "Expressed as a %'age of Sale Price",
    "q_18_g_13_2": "84%",
    "q_18_f_1": "* Assumed 2 years interest at 10% per annum on funds provided",
    "q_18_f_2": "** Paid when clean title is established",
    "q_18_f_3": "*** Paid following the sale of the land",
    "q_19_h": "When will I receive funds?",
    "q_19_p_1": "The Seller will receive funds in two, separate instalments, the Initial Instalment and the Final Settlement.",
    "q_19_p_2": "The Initial Instalment is paid by the Investor when clean title to the land has been established. We expect the time it will take to clean land will be between 3 and 7 years, depending on the complexity of the project.",
    "q_19_p_3": "The Final Settlement (90% of the Net Proceeds from sale) will be paid once the land has been sold which we expect to be between 1 and 2 years from clean title being established."
  },
  "hr": {
    "faq": "ČESTO POSTAVLJANA PITANJA",
    "q_1_h": "Koliki je zapravo problem vlasništva u Hrvatskoj?",
    "q_1_p_1": "Problem je značajan – u Hrvatskoj postoji iznimno velik broj zemljišta bez riješenog vlasničkog pitanja.",
    "q_1_p_2": "Bez čistog vlasništva nije moguća legitimna upotreba zemljišta, stoga takve površine ostaju bezvrijedne. To je loše prvenstveno za potencijalne vlasnike zemljišta koji bi htjeli ostvariti vrijednost od svoje imovine. Također je loše i za državu – ovaj je problem prepreka i za višemilijunska ulaganja kakva trenutno uživaju druge mediteranske države.",
    "q_1_p_3": "Procjenjujemo da bismo rješavanjem ovog problema otključali vrijednost u iznosu od 5 do 10 milijardi eura. Time bismo osigurali prihode vlasnicima zemljišta, ali i potaknuli gospodarstvo, njegov rast i nova zapošljavanja.",
    "q_2_h": "Zašto taj problem još uvijek postoji?",
    "q_2_p_1": "Problem neriješenih vlasničkih odnosa nije isključivo hrvatski problem, ali u Hrvatskoj je izrazito naglašen. Pojavljuje se u zemljama koje su iskusile snažna politička previranja i posljedično masovne migracije.  Problemu pridonose i tradicionalni stavovi o spolnoj nejednakosti, danas u potpunosti nespojivi s postojećim EU i međunarodnim zakonima.",
    "q_2_p_2": "Specifični problemi nastaju zbog sljedećih uzroka:",
    "q_2_l_1": "mnogim vlasnicima, pogotovo onima koji su iselili iz Hrvatske, teško je ući u trag;",
    "q_2_l_2": "neusklađenost zemljišnih knjiga i katastra;",
    "q_2_l_3": "nemogućnost dogovora suvlasnika oko raspodjele zemljišta;",
    "q_2_l_4": "gotovo je nemoguće ući u trag rođacima preminulih vlasnika;",
    "q_2_l_5": "složenost i veliki troškovi pravnih postupaka za uspostavu čistog vlasništva.",
    "q_3_h": "Kako ste riješili te probleme?",
    "q_3_p_1": "Tehnologijom, automatizacijom procesa te trogodišnjim intenzivnim istraživanjima i razvojem. Terra Adriatica je tehnološka tvrtka, u čijim temeljima stoji intenzivno proučavanje hrvatskih zakona. Time smo, tamo gdje pojedinac ima valjano pravo, utabali nezaustavljiv put do uspostave čistog vlasništva nad zemljištem prije njegove prodaje – bez obzira na komplikacije. Našu tvrdnju u potpunosti podupire pravno mišljenje.",
    "q_3_p_2": "Naša upotreba najnovije napredne tehnologije u potpunosti mijenja stvari. Njenom primjenom rješavamo bezbroj iznimno složenih problema tipičnih za ishođenje čistog vlasništva. Za detaljno objašnjenje korištenih metoda, možete posjetiti naš odjeljak <a href='/hr/technology'>Tehnologija</a>.",
    "q_4_h": "Što možete učiniti za mene?",
    "q_4_p_1": "Ako imate legitimno pravo na zemljište i želite ostvariti vrijednost od prodaje tog zemljišta – bez  ikakvih troškova unaprijed – tada Vam možemo pomoći.",
    "q_5_h": "Što ne možete učiniti za mene?",
    "q_5_p_1": "Naš poslovni model oslanja se na prihode od konačne prodaje zemljišta. Postupak uspostave čistog vlasništva je intenzivan i skup, s time da taj trošak pokrivaju vanjski investitori, oslanjajući se na našu procjenu. Bez konačne prodaje zemljišta, ne bismo mogli pokriti te troškove. U skladu s tim, trenutno Vam ne možemo ponuditi nezavisnu uslugu ishođenja čistog vlasništva.",
    "q_5_p_2": "Ako ste zainteresirani isključivo za uspostavu čistog vlasništva ili jednostavno želite detaljnije provjeriti imate li potencijalno pravo na neko zemljište, registriranim korisnicima nudimo opcionalna izvješća.",
    "q_5_p_3": "Molimo posjetite naš odjeljak <a target='_blank' href='/solutions#solutions_reports_table'>Rješenja</a> za više detalja.",
    "q_6_h": "Koliko precizno možete procijeniti imam li potencijalno pravo na neko zemljište?",
    "q_6_p_1": "Uz Vaše dopuštenje i koristeći podatke koje nam pošaljete, povezujemo ih s desecima milijuna javnih zapisa iz različitih javnih izvora, uključujući zemljišne knjige i katastar.",
    "q_6_p_2": "Dobivene rezultate analiziramo koristeći vlastiti sustav umjetne inteligencije (UI). U obzir uzimamo brojne faktore koji bi na njih mogli utjecati, poput promjena u imenima tijekom godina ili njihovih pogrešnih zapisa u registrima, da bismo dobili pokazatelje što i gdje možda posjedujete. Svaki rezultat potom ispitujemo da bismo što je moguće točnije procijenili koliko bi ishođenje čistog vlasništva moglo potrajati.",
    "q_6_p_3": "Zemljišne knjige prepune su nedostataka, kao što su nepostojeći ili u potpunosti netočni zapisi. Često nailazimo na slučajeve u kojima je pojedinac naslijedio zemljište, no njegovo pravo nije uknjiženo. Naš <i>Locksmith</i> proizvod odnosi se na svakog od spomenutih slučajeva, osiguravajući nezaustavljiv postupak ishođenja čistog vlasništva i prodaje zemljišta.",
    "q_6_p_4": "Uspostava čistog vlasništva dug je i složen proces. Budite oprezni ako Vam itko govori drugačije.",
    "q_7_h": "Jamčite li mi da ćete uzeti moj slučaj?",
    "q_7_p_1": "To što radimo, između ostaloga, oslanja se i na ekonomiju razmjera. Naime, da bi proces bio održiv, možda ćemo morati konsolidirati više zemljišta na nekom području. U trenutku kada dosegnemo kritičnu masu površine, započinjemo s uspostavom čistog vlasništva i izlažemo se svim troškovima vezanim za taj proces.",
    "q_7_p_2": "Stoga ćemo Vas prvo pitati želite li se uključiti u Registar zainteresiranih korisnika. Kada to učinite, možemo započeti s radom na stvaranju kritične mase zemljišta za određeno područje. Time prelazimo na iduću fazu uspostave čistog vlasništva prije konačne prodaje zemljišta.",
    "q_7_p_3": "Čak i ako je vjerojatnost da posjedujete zemljište označena kao niska, Vaše podatke čuvamo sve dok imate aktivan Terra Adriatica račun, za slučaj da se promijene okolnosti (naprimjer, ako u našoj bazi dođe do promjene koja će promijeniti Vaš status). U tom slučaju, ponovno ćemo Vas kontaktirati i obavijestiti o mogućnostima.",
    "q_8_h": "Jedan sam od više suvlasnika jednog zemljišta – što dalje?",
    "q_8_p_1": "Svaki suvlasnik zemljišta ima pravo pokrenuti proces za ishođenjem vlasništva nad cjelokupnim zemljištem. Koristeći taj proces ali i razne druge metode surađivat ćemo i s ostalim vlasnicima, a sve u cilju ubrzanja procesa. Bez obzira koliko dugo traje, proces ćemo izvršiti – jednom pokrenut, proces je nezaustavljiv.",
    "q_9_h": "Koliko će trajati prodaja mog zemljišta?",
    "q_9_p_1": "Brojni su čimbenici koji utječu na trajanje procesa. Jedan od čimbenika, spomenut ranije, je dosezanje kritične mase površine zemljišta unutar određenog područja da bismo osigurali financijsku održivost projekta.",
    "q_9_p_2": "Kad se odlučimo na sljedeći korak i osiguramo sredstva, cilj nam je uspostava čistog  vlasništva u roku od 3 do 4 godine. Čim se uspostavi čisto vlasništvo, surađivat ćemo sa svim stranama kako bismo povećali vrijednost tog zemljišta prije prodaje. Očekujemo da će nam za to biti potrebno između 12 i 24 mjeseca.",
    "q_10_h": "Kako sve to postići bez plaćanja unaprijed?",
    "q_10_p_1": "U današnjoj ekonomiji, investitorima je sve teže doći do održivih povrata investicija. <i>Locksmith</i> je stoga jedinstvena prilika za sve njegove sudionike.",
    "q_10_p_2": "Investitore koje traže prihode od ulaganja osiguranih s imovinskim kolateralima,  spajamo s prodavateljima koji žele ostvariti vrijednost od svog zemljišta.",
    "q_10_p_3": "Terra Adriatica će dokazati investitorima da je sposobna osigurati čisto vlasništvo prije prodaje. Zauzvrat, investitori će financirati proces i zajamčiti prodavatelju isplatu inicijalne rate zajamčene vrijednosti  kad se uspostavi čisto vlasništvo. Nakon što zemlja bude prodana prodavatelj će primiti konačnu isplatu koja, uključujući inicijalnu ratu zajamčene vrijednosti, iznosi minimalno 80% od ukupne ostvarene cijene.",
    "q_11_h": "Tko odlučuje kolika je vrijednost zemljišta?",
    "q_11_p_1": "U konačnici, odlučit će tržište. Naš <i>Locksmith</i> koncipiran je kako bi svim stranama – Vama (prodavatelju), Terra Adriatici i Investitoru – osigurao maksimalan poticaj i uskladio interese, da bismo postigli što veću moguću vrijednost zemljišta prilikom prodaje.",
    "q_11_p_2": "Radit ćemo s potpunom transparentnošću u cilju postizanja najviše moguće cijene. Nekoliko je načina da to postignemo: naprimjer, Vaše zemljište udružit ćemo sa susjednima, da bismo postigli veću površinu zemljišta, a time i snažniji interes projektanata. Također, tamo gdje je moguće, nastojat ćemo prenamijeniti zemljište iz, recimo, poljoprivrednog u građevinsko, kako bismo potaknuli priljev investicija. Za taj proces očekujemo i podršku lokalnih vlasti.",
    "q_12_h": "Što mi osiguravate?",
    "q_12_p_1": "Kao dio <i>Locksmitha</i>, investitor će Vam garantirati isplatu inicijalne rate zajamčene vrijednosti kada se uspostavi čisto vlasništvo. Taj inicijalni iznos odražava investitorov rizik te je znatno niži od očekivane prodajne cijene.",
    "q_12_p_2": "Nakon ishođenja čistog vlasništva, maksimizirat ćemo vrijednost zemljišta, kao što je objašnjeno u prethodnom odgovoru. Ovisno o složenosti pojedinog slučaja, taj proces mogao bi trajati 1-2 godine. Kada prodamo zemljište, prema ugovoru ćete primiti daljnju uplatu, kojom Vaš udio u cjelokupnom procesu doseže barem 80% vrijednosti prodajne cijene.",
    "q_13_h": "Što ako se predomislim u vezi prodaje zemljišta?",
    "q_13_p_1": "Jednom kad sklopite <i>Locksmith</i> ugovor, obvezni ste prodati zemljište, jer to je jedini način da svaka strana <i>Locksmitha</i> dobije povrat svojih ulaganja u realizaciju procesa.",
    "q_14_h": "Ako pristanem potpisati <i>Locksmith</i> ugovor, koje su moje obveze?",
    "q_14_p_1": "Sklapanjem <i>Locksmitha</i>, obvezujete se da ćete nam pružiti uvid u sve informacije vezane za zemljište ako, kako i kada ih zatražimo od Vas. Kada uspostavimo čisto vlasništvo, Vaše zemljište ujedno postaje i zalog – zauzvrat primate inicijalnu ratu prije prodaje i svoj udio od cjelokupnog procesa nakon prodaje.",
    "q_15_h": "Kako Terra Adriatica ostvaruje zaradu?",
    "q_15_p_1": "Jedini način na koji zarađujemo od <i>Locksmith</i> ugovora je prilikom prodaje zemljišta – tada uzimamo naknadu za pružene usluge u visini 10% od prodajne vrijednosti.",
    "q_15_p_2": "Neovisno o tome, u svakom trenutku za kupnju su Vam dostupna i opcionalna izvješća, u kojima Vam dajemo pregled mogućih prava na zemljište. Nekoliko je vrsti izvješća, koja se razlikuju u razini prikazanih detalja. Za više informacija, možete posjetiti naš odjeljak <a href='/hr/solutions'>Rješenja</a>.",
    "q_16_h": "Zašto koristiti Terra Adriaticu?",
    "q_16_p_1": "Naše usluge možete koristiti ako želite ostvariti vrijednost od prodaje zemljišta na koje imate pravo.",
    "q_16_p_2": "To biste mogli postići i sami – pod uvjetom da imate neograničeno mnogo vremena i novaca. Ako se ipak odlučite za taj smjer, možemo Vam ponuditi naša plaćena izvješća kako bismo Vam pomogli na ovom iznimno napornom putu. Međutim, uvjereni smo da ćemo se mi pokazati kao Vaš najučinkovitiji suputnik u ostvarivanju tog cilja.",
    "q_16_p_3": "Čak i ako smatramo da je vjerojatnost da imate vlasnička prava mala, bilo bi dobro da nam, bez drugih obaveza s Vaše strane,  omogućite daljnje čuvanje Vaših podataka – možemo Vas kontaktirati kasnije, ako se pojave nove informacije koje bi mogle utjecati na Vaš status u našoj bazi. Baze podataka neprestano se ažuriraju, stoga se Vaši izgledi za posjedovanje vlasničkih prava mogu promijeniti.",
    "q_17_h": "Što je početna rata?",
    "q_17_p_1": "Početna rata je iznos kojeg  Investitor plaća Prodavatelju i njome se osigurava mehanizam kojim se sva „očišćena“ zemljišta unutar jednog projekta konsolidiraju u jedinstveni pravni entitet.",
    "q_17_p_2": "Ovaj mehanizam je nužan da bi se omogućila učinkovita prodaja zemljišta. U suprotnom, bilo bi iznimno teško, gotovo nemoguće izvršiti prodaju različitih zemljišta koja su u suvlasništvu možda čak i stotina ljudi, a za što bi bile potrebne stotine ugovora/ potpisa/ pregovora. ",
    "q_17_p_3": "Sâmo zemljište predstavlja osiguranje za početnu ratu koju isplaćuje Investitor. Nadalje, Investitor nema prava zatražiti povrat iznosa ako se zemljište zbog bilo kojeg razloga ne može prodati. Svi prodavatelji zadržavaju svoja prava na udio od konačne prodaje nakon primanja iznosa početne rate.",
    "q_17_p_4": "S obzirom da Investitor preuzima rizik hoće li zemljište biti prodano, početna rata bit će nizak postotak željene prodajne cijene – najvjerojatnije oko 5%.",
    "q_17_p_5": "Investitor  će pod pravnom obvezom i nadzorom Terra Adriatice nastojati postići prodaju zemljišta po najvišoj mogućoj cijeni. Zatim će nadoknaditi svoje troškove uz prinos te poticajnu naknadu u iznosu od 2% vrijednosti razlike između konačne prodajne cijene i početne rate.",
    "q_17_p_6": "Spomenuta poticajna naknada osigurava usklađivanje interesa svih triju strana za postizanje najviše moguće cijene prilikom prodaje zemljišta.",
    "q_17_p_7": "Preostali iznos (neto prihod) dijeli se između Prodavatelja, kojem pripada 90% iznosa, i Terra Adriatice, kojoj pripada 10% iznosa.",
    "q_18_h": "Koliki iznos dobivam prodajom svog zemljišta?",
    "q_18_p_1": "Primit ćete 90% konačnog neto prihoda od prodaje zemljišta. Računajući i početnu ratu, realno očekujemo da će Vam pripasti više od 80% iznosa prodajne cijene.",
    "q_18_p_2": "Konačan neto prihod je ostatak prihoda od prodaje zemljišta nakon što se oduzmu sve naknade i vezani troškovi.",
    "q_18_p_3": "Važno je znati da su troškovi Investitora i početna kamata od 2% poznata varijabla, no troškovi vezani uz postupak ishođenja čistog vlasništva (npr. pravne i geodetske naknade itd.) neizbježno ovise o složenosti slučaja.",
    "q_18_p_4": "Međutim, očekujemo da spomenuti vezani troškovi neće znatno utjecati na konačan neto prihod – naša procjena je da će iznositi između 2% i 5% od konačne prodajne cijene.",
    "q_18_p_5": "Niže je ilustracija troškova, uz pretpostavku da će se zemljište prodati po cijeni od €100.000 i da će varijabilni troškovi biti 4% od prodajne cijene (što je blizu gornje granice očekivanih troškova).",
    "q_18_g_1_1": "Postignuta prodajna cijena:",
    "q_18_g_1_2": "€ 100.000",
    "q_18_g_2_1": "Umanjena za:",
    "q_18_g_3_1": "Procijenjeni varijabilni troškovi",
    "q_18_g_3_2": "- € 4.000",
    "q_18_g_4_1": "Povrat početne rate (već plaćene Prodavatelju)",
    "q_18_g_4_2": "- € 5.000",
    "q_18_g_5_1": "Investitorov prinos *",
    "q_18_g_5_2": "- € 1.800",
    "q_18_g_6_1": "Investitorova poticajna naknada 2%",
    "q_18_g_6_2": "- € 1.900",
    "q_18_g_7_1": "Neto prodajni prihodi:",
    "q_18_g_7_2": "€ 87.300",
    "q_18_g_8_1": "Udio Terra Adriatice 10% od prodajnih prihoda",
    "q_18_g_8_2": "- € 8.730",
    "q_18_g_9_1": "Ukupni prihodi:",
    "q_18_g_10_1": "Početna rata **",
    "q_18_g_10_2": "€ 5.000",
    "q_18_g_11_1": "Konačan iznos *** (90% od neto prodajnih prihoda)",
    "q_18_g_11_2": "€ 78.570",
    "q_18_g_12_1": "Prihodi Prodavatelja ukupno:",
    "q_18_g_12_2": "€ 83.570",
    "q_18_g_13_1": "Izraženo u postotku od prodajne cijene",
    "q_18_g_13_2": "84%",
    "q_18_f_1": "* Pretpostavljen godišnji prinos od 10% od investicije u trajanju 2 god.",
    "q_18_f_2": "** Plaćena kad se uspostavi čisto vlasništvo",
    "q_18_f_3": "*** Plaćen nakon prodaje zemljišta",
    "q_19_h": "Kada ću primiti sredstva?",
    "q_19_p_1": "Prodavatelj će primiti sredstva u dvije rate: početna rata i konačan iznos.",
    "q_19_p_2": "Početnu ratu plaća Investitor kada se uspostavi čisto vlasništvo nad zemljištem. Očekivano potrebno vrijeme za uspostavu čistog vlasništva je između 3 i 7 godina, ovisno o složenosti slučaja.",
    "q_19_p_3": "Konačan iznos (90% od neto prodajnih prihoda) bit će isplaćen nakon prodaje zemljišta. Očekivano vrijeme za prodaju zemljišta je između 1 i 2 godine od uspostave čistog vlasništva."
  },
  "es": {
    "faq": "PREGUNTAS FRECUENTES",
    "q_1_h": "¿Qué tan grande es el problema de la propiedad de terrenos en Croacia?",
    "q_1_p_1": "Es un problema significativo. En Croacia hay vastas cantidades de terrenos sin títulos limpios.",
    "q_1_p_2": "Sin títulos limpios, esos terrenos efectivamente no tienen valor, ya que este hecho prohíbe el uso legítimo. Esto es malo para los propietarios potenciales del terreno que quieren realizar el valor de su propiedad. También es malo para el país, ya que actúa como una barrera para los miles de millones de euros de inversión extranjera de los que otros países del Mediterráneo disfrutan.",
    "q_1_p_3": "Estimamos que, al resolver estos problemas, podríamos desbloquear entre 5 y 10 mil millones de euros de valor, proporcionando ingresos a los propietarios, y un impulso sustancial a la economía, apoyando el empleo y el crecimiento.",
    "q_2_h": "¿Por qué todavía existen estos problemas?",
    "q_2_p_1": "Estos problemas no existen solamente en Croacia, pero son especialmente frecuentes aquí. Surgen en países que han experimentado agitación política y revolucionaria que ha provocado una migración masiva combinada con actitudes tradicionales sobre la desigualdad de género, ahora totalmente incompatibles con el derecho internacional y de la UE existente.",
    "q_2_p_2": "Surgen problemas específicos debido a:",
    "q_2_l_1": "La incapacidad de rastrear a los propietarios, muchos de los cuales han abandonado el país.",
    "q_2_l_2": "Inconsistencias entre diferentes registros de la propiedad y el catastro.",
    "q_2_l_3": "Múltiples propietarios de terreno que no pueden ponerse de acuerdo en cuanto al coeficiente de copropiedad.",
    "q_2_l_4": "Dificultad de localizar a familiares de propietarios fallecidos.",
    "q_2_l_5": "La complejidad y el coste de navegar por el sistema legal para establecer un título limpio.",
    "q_3_h": "¿Cómo han resuelto estos problemas?",
    "q_3_p_1": "Tecnología, automatización de procesos y tres años de intensa investigación y desarrollo. Terra Adriatica es una compañía impulsada por la tecnología, respaldada por una exhaustiva revisión de la legislación croata para establecer, donde un individuo tiene un reclamo válido, una ruta inexorable para establecer un título limpio de terreno antes de su venta, sin importar la complicación. Nuestra afirmación está totalmente respaldada por la opinión jurídica.",
    "q_3_p_2": "Nuestro uso de la tecnología más reciente y más avanzada completamente cambia las cosas. Aplicamos esta tecnología para resolver la miríada de problemas altamente complejos que son típicos en el proceso de establecer un título limpio. Visite nuestra <a href='/es/technology'>sección de Tecnología</a> para obtener una explicación más detallada de los métodos que utilizamos.",
    "q_4_h": "¿Qué puede hacer por mí?",
    "q_4_p_1": "Si tiene un reclamo válido de terreno y desea realizar el valor de la venta de dicho terreno sin coste inicial para usted, podemos ayudarle.",
    "q_5_h": "¿Qué no puede hacer por mí?",
    "q_5_p_1": "Nuestro modelo de negocio se basa en generar ingresos de la venta final de la tierra. El proceso para establecer un título limpio es intensivo y costoso, con ese coste cubierto por Inversores de terceros dispuestos a prestar basándose en nuestra evaluación. Sin una venta al final del proceso, no podríamos cubrir esos costes. Por consiguiente, en este momento no podemos ofrecer un servicio independiente, con el que solo se establecería el título limpio.",
    "q_5_p_2": "Para aquellos interesados únicamente en establecer un título limpio de terreno o para cualquiera que simplemente desee entender con más detalle qué posible reclamo de terreno podría tener, ofrecemos informes opcionales de pago a los usuarios registrados.",
    "q_5_p_3": "Visite nuestra sección de <a target='_blank' href='/solutions#solutions_reports_table'>Soluciones</a> para obtener más información.",
    "q_6_h": "¿Qué tan precisa es su estimación inicial del terreno que podría poseer?",
    "q_6_p_1": "Con su permiso y utilizando la información que usted envía, correlacionamos esos datos con decenas de millones de registros públicos de múltiples y diversas fuentes públicas, incluyendo el registro de la propiedad y el catastro.",
    "q_6_p_2": "Usando nuestra plataforma de IA, analizamos entonces esos resultados, teniendo en cuenta una multitud de factores que pueden influir en ellos, como la variación de los nombres a lo largo de los años y errores ortográficos en los registros para darnos una indicación de lo que usted puede poseer y dónde. Luego examinamos cada resultado para poder evaluar internamente el tiempo que se necesitaría para lograr el título antes de una venta con el mayor nivel de certeza posible.",
    "q_6_p_3": "Hay muchas imperfecciones en el registro de la propiedad, como la cuestión de registros inexistentes o registros totalmente inexactos. A menudo encontramos casos en los que una persona ha heredado terreno, pero ese derecho de terreno no se ha inscrito en el registro público. Nuestro proceso de Locksmith aborda cada uno de estos, proporcionando un proceso inexorable para establecer un título limpio y realizar la venta.",
    "q_6_p_4": "Establecer un título limpio de un terreno es un proceso largo y complejo. Ten cuidado con cualquiera que le diga lo contrario.",
    "q_7_h": "¿Me garantizan que tomarán mi caso?",
    "q_7_p_1": "En parte, podemos hacer lo que hacemos debido a las economías de escala. Para hacer viable el proceso, es posible que necesitemos fusionar diversas parcelas dentro de un área. Una vez que alcancemos la masa crítica, podemos iniciar el proceso e incurrir en el coste relacionado.",
    "q_7_p_2": "Por lo tanto, al principio le pedimos que acepte unirse a nuestro registro de interés. Una vez que lo haya hecho, nos ponemos a trabajar para establecer una masa crítica para el proyecto en este área, de modo que podamos pasar a la siguiente etapa de establecer un título limpio antes de la venta final de ese terreno.",
    "q_7_p_3": "Incluso si tiene terreno clasificado como terreno de baja probabilidad, siempre que tenga una cuenta con nosotros, conservaremos sus datos archivados si la situación cambia – por ejemplo, si se actualiza el registro, cambiando su estado. En ese caso, nos comunicaremos con usted de nuevo para informarle sobre sus opciones.",
    "q_8_h": "Soy uno de los muchos propietarios de la misma parcela. ¿Cómo funciona eso?",
    "q_8_p_1": "Cada copropietario del terreno tiene derecho a iniciar un procedimiento para establecer un título limpio de toda la parcela en la que está incluido. Utilizamos estos procedimientos y varios métodos para rastrear e interactuar con otros propietarios para acelerar el proceso. Por mucho tiempo que tarde el proceso, se logrará. Es inexorable.",
    "q_9_h": "¿Cuánto tardará la venta de mi terreno?",
    "q_9_p_1": "Hay muchos factores que afectan el tiempo que el proceso tomará. Estos incluyen, como se menciona en la respuesta anterior, alcanzar una masa crítica de parcelas dentro de un área para asegurar la viabilidad económica de ese proyecto.",
    "q_9_p_2": "Una vez que estemos listos para avanzar y asegurar la financiación, nuestro objetivo es establecer un título limpio en un plazo de 3-4 años. Una vez establecido el título limpio, trabajamos con todas las partes para maximizar el valor de ese terreno antes de su venta – un proceso que esperamos que se logre entre 12 y 24 meses.",
    "q_10_h": "¿Cómo puede lograr todo esto sin coste inicial?",
    "q_10_p_1": "A los inversores les resulta cada vez más difícil en la economía actual generar rendimientos sostenibles de la inversión. Por lo tanto, Locksmith presenta una oportunidad única a todas las partes del contrato.",
    "q_10_p_2": "Emparejamos a los inversores que buscan rendimientos respaldados por activos con vendedores que quieren realizar el valor de sus terrenos.",
    "q_10_p_3": "Terra Adriatica podrá demostrar a los inversores su capacidad de asegurar títulos limpios antes de la venta y los inversores, a cambio, financiarán el proceso y garantizarán al vendedor un Primer Pago cuando se establezca el título limpio. Una vez que se venda el terreno, usted como vendedor recibirá un pago final que cuando se combina con el Pago Inicial equivaldrá a un mínimo de 80 % neto del valor total logrado.",
    "q_11_h": "¿Quién decide cuánto vale el terreno?",
    "q_11_p_1": "Al final, el mercado decidirá. Nuestro producto Locksmith está diseñado específicamente para garantizar que todas las partes – usted (como vendedor), Terra Adriatica y el Inversor – sean plenamente incentivadas y por lo tanto alineadas para asegurar el valor más alto posible a la venta.",
    "q_11_p_2": "Trabajaremos con plena transparencia para lograr el máximo precio posible. Lo hacemos de varias maneras, incluyendo a través de fusionar su terreno con parcelas contiguas para establecer un área más grande que pueda atraer más interés de un desarrollador. También intentaremos (cuando sea posible) solicitar un cambio en la zonificación de ese terreno de, por ejemplo, tierra agrícola a terreno edificable, para apoyar la inversión extranjera, lo que esperamos que las autoridades locales apoyen.",
    "q_12_h": "¿Qué garantías tengo?",
    "q_12_p_1": "Como parte del contrato Locksmith, el inversor le garantizará un Primer Pago cuando se establezca un título limpio. Este Primer Pago reflejará el riesgo que el inversor está dispuesto a asumir y, por lo tanto, será significativamente inferior al precio previsto de venta.",
    "q_12_p_2": "Una vez que se haya establecido el título limpio, trabajaremos para maximizar el valor de ese terreno como se explicó anteriormente. Este proceso llevará 1-2 años dependiendo de la complejidad. Una vez vendido y según el contrato, recibirá un pago adicional, elevando su participación total en los ingresos totales a por lo menos 80 % de los ingresos netos de la venta.",
    "q_13_h": "¿Qué pasa si cambio de opinión sobre la venta del terreno?",
    "q_13_p_1": "Una vez que celebra el contrato Locksmith, está comprometido con la venta del terreno, ya que esto es cómo cada parte en el contrato Locksmith consigue un retorno de su inversión respectiva en la realización del proceso.",
    "q_14_h": "Si acepto celebrar un contrato Locksmith, ¿cuáles son mis compromisos?",
    "q_14_p_1": "Una vez que haya firmado un contrato Locksmith, se comprometerá a proporcionarnos información si, como y cuando la necesitamos, así como a proporcionar el terreno como garantía cuando se haya conseguido un título limpio, a cambio de lo cual usted, como vendedor, recibirá un primer pago antes de la venta del terreno, y su parte de los ingresos después de la venta.",
    "q_15_h": "¿Cómo gana dinero Terra Adriatica?",
    "q_15_p_1": "El único momento en que ganamos dinero de un contrato Locksmith es el momento de la venta del terreno – en ese momento tomamos el 10 % de los ingresos netos de la venta como un honorario por nuestros servicios.",
    "q_15_p_2": "Aparte de esto, también ofrecemos informes opcionales que puede comprar en cualquier momento, en los que se describen los posibles derechos de terreno que creemos que usted puede tener. Estos informes varían en cuanto a los detalles que proporcionan. Consulte nuestra página de <a href='/es/solutions'>Soluciones</a> para obtener más información.",
    "q_16_h": "¿Por qué usaría Terra Adriatica?",
    "q_16_p_1": "Al fin y al cabo, usaría nuestros servicios si desea realizar el valor de la venta de terrenos sobre los que puede tener derechos.",
    "q_16_p_2": "Lograr esto por usted mismo es posible con la condición de tener tiempo y dinero ilimitados. Si decide hacerlo, podemos proporcionarle acceso a nuestros informes pagados para ayudarle a realizar ese esfuerzo considerable. Sin embargo, estamos convencidos de ser el socio más eficaz para usted.",
    "q_16_p_3": "Incluso si consideramos que la probabilidad de que tenga derechos sea baja, puede considerar la opción de guardar sus datos con nosotros sin ninguna obligación, para que podamos contactarle en el futuro si aparece nueva información. Las bases de datos se actualizan constantemente, así que sus perspectivas pueden cambiar.",
    "q_17_h": "¿Qué es el Primer Pago?",
    "q_17_p_1": "El Primer Pago es pagado por el Inversor al Vendedor y proporciona el mecanismo a través de cual todos los terrenos limpios en un proyecto se fusionan en una entidad legal.",
    "q_17_p_2": "Este mecanismo es necesario para facilitar una venta eficiente de diferentes parcelas de terreno, a lo mejor con cientos de copropietarios, lo que requería cientos de contratos/firmas/negociaciones y sería difícil o casi imposible de vender.",
    "q_17_p_3": "El Inversor asegura este pago solamente en el terreno y no tiene derecho a reclamar el dinero gastado si, por cualquier razón, el terreno no se puede vender. Sin embargo, todos los vendedores conservan sus derechos a su parte de los ingresos de la venta final después de haber recibido su primer pago.",
    "q_17_p_4": "Dado que el Inversor está asumiendo todo el riesgo en el terreno que se vende, el Primer Pago será un pequeño porcentaje del precio que se espera lograr en la venta, probablemente alrededor del 5 %.",
    "q_17_p_5": "El Inversor será contratado legalmente y supervisado por Terra Adriatica para vender el terreno al precio más alto posible. El Inversor entonces recuperará sus costes más intereses, y también tomará un honorario de incentivo del 2 % de la diferencia entre el precio de la venta final y el primer pago.",
    "q_17_p_6": "El incentivo del Inversor asegura la alineación de intereses de todas las partes para lograr el precio más alto posible en la venta.",
    "q_17_p_7": "La suma restante (los ingresos netos) será distribuida entre el Vendedor (90 %) y Terra Adriatica (10 %).",
    "q_18_h": "¿Cuánto obtendré de la venta de mi tierra?",
    "q_18_p_1": "Recibirá el 90 % de los ingresos netos de la venta del terreno. Junto con el Primer Pago, esperamos de manera realista que esto equivaldrá a más del 80 % del precio conseguido en la venta.",
    "q_18_p_2": "Los ingresos netos se definen como el saldo de los fondos disponibles de la venta final del terreno después de deducir todos los honorarios de financiación y los costes asociados.",
    "q_18_p_3": "Es importante tener en cuenta que, si bien se conocen los gastos de financiación y los honorarios de incentivo del 2 % que pertenecen al Inversor, los costes relacionados con el establecimiento de títulos legales (por ejemplo, honorarios legales y de agrimensura, etc.) son inevitablemente variables y dependen de la complejidad de la tarea.",
    "q_18_p_4": "Sin embargo, no anticipamos que estos costes asociados afecten significativamente la cifra de ingresos netos. Estimamos que el impacto será entre el 2 % y el 5 % del precio de venta final.",
    "q_18_p_5": "La siguiente ilustración supone que el terreno se vende por 100 000 € y que los costes asociados equivalen al 4 % del precio de la venta, lo que se situaría en el extremo superior de costes previstos.",
    "q_18_g_1_1": "Precio de venta logrado:",
    "q_18_g_1_2": "€ 100.000",
    "q_18_g_2_1": "Menos:",
    "q_18_g_3_1": "Estimación de costes variables",
    "q_18_g_3_2": "- € 4.000",
    "q_18_g_4_1": "Primer Pago (ya pagado al Vendedor) recuperado",
    "q_18_g_4_2": "- € 5.000",
    "q_18_g_5_1": "Intereses del Inversor *",
    "q_18_g_5_2": "- € 1.800",
    "q_18_g_6_1": "Honorario de incentivo del Inversor del 2 %",
    "q_18_g_6_2": "- € 1.900",
    "q_18_g_7_1": "Ingresos netos de la venta:",
    "q_18_g_7_2": "€ 87.300",
    "q_18_g_8_1": "La participación de Terra Adriatica del 10 % de los ingresos netos",
    "q_18_g_8_2": "- € 8.730",
    "q_18_g_9_1": "Ingresos totales:",
    "q_18_g_10_1": "Primer Pago **",
    "q_18_g_10_2": "€ 5.000",
    "q_18_g_11_1": "Pago Final *** (90 % de los ingresos netos)",
    "q_18_g_11_2": "€ 78.570",
    "q_18_g_12_1": "Ingresos totales del Vendedor:",
    "q_18_g_12_2": "€ 83.570",
    "q_18_g_13_1": "Expresado como porcentaje del precio de venta",
    "q_18_g_13_2": "84 %",
    "q_18_f_1": "* Se asume un interés anual del 10 % sobre los fondos proporcionados",
    "q_18_f_2": "** Pagado cuando se establece el título limpio",
    "q_18_f_3": "*** Pagado después de la venta del terreno",
    "q_19_h": "¿Cuándo recibiré los fondos?",
    "q_19_p_1": "El Vendedor recibirá fondos en dos pagos separados, el Primer Pago y el Pago Final.",
    "q_19_p_2": "El Inversor paga el Primer Pago cuando se haya establecido un título limpio del terreno. Esperamos que el tiempo necesario para establecer un título limpio sea entre 3 y 7 años, dependiendo de la complejidad del proyecto.",
    "q_19_p_3": "El Pago Final (90 % de los ingresos netos de la venta) se pagará una vez que se haya vendido el terreno. La estimación de esta fase es entre 1 y 2 años del establecimiento del título limpio."
  }
}
//</i18n>

import FaqGridAnimation from "./FaqGridAnimation";

export default {
  name: "TopContFaq",
  components: { FaqGridAnimation },
  data() {
    return {
      t: t[this.$lang],
      accordion_active: null,
      faq_content: []
    };
  },
  mounted() {
    const vm = this;

    vm.faq_content = [
      {
        active: false,
        h: vm.t.q_1_h,
        p: [vm.t.q_1_p_1, vm.t.q_1_p_2, vm.t.q_1_p_3]
      },
      {
        active: false,
        h: vm.t.q_2_h,
        html:
          "" +
          "<p>" +
          vm.t.q_2_p_1 +
          "</p>" +
          "<p class='font-weight-bold'>" +
          vm.t.q_2_p_2 +
          "</p>" +
          "<ol>" +
          "<li>" +
          vm.t.q_2_l_1 +
          "</li>" +
          "<li>" +
          vm.t.q_2_l_2 +
          "</li>" +
          "<li>" +
          vm.t.q_2_l_3 +
          "</li>" +
          "<li>" +
          vm.t.q_2_l_4 +
          "</li>" +
          "<li>" +
          vm.t.q_2_l_5 +
          "</li>" +
          "</ol>"
      },
      {
        active: false,
        h: vm.t.q_3_h,
        p: [vm.t.q_3_p_1, vm.t.q_3_p_2]
      },
      {
        active: false,
        h: vm.t.q_4_h,
        p: [vm.t.q_4_p_1]
      },
      {
        active: false,
        h: vm.t.q_5_h,
        p: [vm.t.q_5_p_1, vm.t.q_5_p_2, vm.t.q_5_p_3]
      },
      {
        active: false,
        h: vm.t.q_6_h,
        p: [vm.t.q_6_p_1, vm.t.q_6_p_2, vm.t.q_6_p_3, vm.t.q_6_p_4]
      },
      {
        active: false,
        h: vm.t.q_7_h,
        p: [vm.t.q_7_p_1, vm.t.q_7_p_2, vm.t.q_7_p_3]
      },
      {
        active: false,
        h: vm.t.q_8_h,
        p: [vm.t.q_8_p_1]
      },
      {
        active: false,
        h: vm.t.q_9_h,
        p: [vm.t.q_9_p_1, vm.t.q_9_p_2]
      },
      {
        active: false,
        h: vm.t.q_10_h,
        p: [vm.t.q_10_p_1, vm.t.q_10_p_2, vm.t.q_10_p_3]
      },
      {
        active: false,
        h: vm.t.q_17_h,
        p: [
          vm.t.q_17_p_1,
          vm.t.q_17_p_2,
          vm.t.q_17_p_3,
          vm.t.q_17_p_4,
          vm.t.q_17_p_5,
          vm.t.q_17_p_6,
          vm.t.q_17_p_7
        ]
      },
      {
        active: false,
        h: vm.t.q_18_h,
        html:
          "" +
          "<p>" +
          vm.t.q_18_p_1 +
          "</p>" +
          "<p>" +
          vm.t.q_18_p_2 +
          "</p>" +
          "<p>" +
          vm.t.q_18_p_3 +
          "</p>" +
          "<p>" +
          vm.t.q_18_p_4 +
          "</p>" +
          "<p>" +
          vm.t.q_18_p_5 +
          "</p>" +
          "<div class='grid_faq_cont'>" +
          "<div class='grid_faq'>" +
          "<div class='line_s'></div>" +
          "<div class='grid_1 fw-600'>" +
          vm.t.q_18_g_1_1 +
          "</div>" +
          "<div class='grid_2 fw-600'>" +
          vm.t.q_18_g_1_2 +
          "</div>" +
          "<div class='line_s'></div>" +
          "<div class='grid_1 fw-600'>" +
          vm.t.q_18_g_2_1 +
          "</div>" +
          "<div class='grid_2 fw-600'>" +
          vm.t.q_18_g_2_2 +
          "</div>" +
          "<div class='line_d'></div>" +
          "<div class='grid_1'>" +
          vm.t.q_18_g_3_1 +
          "</div>" +
          "<div class='grid_2'>" +
          vm.t.q_18_g_3_2 +
          "</div>" +
          "<div class='line_d'></div>" +
          "<div class='grid_1'>" +
          vm.t.q_18_g_4_1 +
          "</div>" +
          "<div class='grid_2'>" +
          vm.t.q_18_g_4_2 +
          "</div>" +
          "<div class='line_d'></div>" +
          "<div class='grid_1'>" +
          vm.t.q_18_g_5_1 +
          "</div>" +
          "<div class='grid_2'>" +
          vm.t.q_18_g_5_2 +
          "</div>" +
          "<div class='line_d'></div>" +
          "<div class='grid_1'>" +
          vm.t.q_18_g_6_1 +
          "</div>" +
          "<div class='grid_2'>" +
          vm.t.q_18_g_6_2 +
          "</div>" +
          "<div class='line_s'></div>" +
          "<div class='grid_1 fw-600'>" +
          vm.t.q_18_g_7_1 +
          "</div>" +
          "<div class='grid_2 fw-600'>" +
          vm.t.q_18_g_7_2 +
          "</div>" +
          "<div class='line_s'></div>" +
          "<div class='grid_1'>" +
          vm.t.q_18_g_8_1 +
          "</div>" +
          "<div class='grid_2'>" +
          vm.t.q_18_g_8_2 +
          "</div>" +
          "<div class='line_s'></div>" +
          "<div class='grid_1 fw-600'>" +
          vm.t.q_18_g_9_1 +
          "</div>" +
          "<div class='grid_2 fw-600'>" +
          vm.t.q_18_g_9_2 +
          "</div>" +
          "<div class='line_s'></div>" +
          "<div class='grid_1'>" +
          vm.t.q_18_g_10_1 +
          "</div>" +
          "<div class='grid_2'>" +
          vm.t.q_18_g_10_2 +
          "</div>" +
          "<div class='line_d'></div>" +
          "<div class='grid_1'>" +
          vm.t.q_18_g_11_1 +
          "</div>" +
          "<div class='grid_2'>" +
          vm.t.q_18_g_11_2 +
          "</div>" +
          "<div class='line_s'></div>" +
          "<div class='grid_1 fw-600'>" +
          vm.t.q_18_g_12_1 +
          "</div>" +
          "<div class='grid_2 fw-600'>" +
          vm.t.q_18_g_12_2 +
          "</div>" +
          "<div class='line_s'></div>" +
          "<div class='grid_1'>" +
          vm.t.q_18_g_13_1 +
          "</div>" +
          "<div class='grid_2'>" +
          vm.t.q_18_g_13_2 +
          "</div>" +
          "</div>" +
          "<div class='faq_fusnotes'>" +
          "<p class='help_text'>" +
          vm.t.q_18_f_1 +
          "</p>" +
          "<p class='help_text'>" +
          vm.t.q_18_f_2 +
          "</p>" +
          "<p class='help_text'>" +
          vm.t.q_18_f_3 +
          "</p>" +
          "</div>" +
          "</div>"
      },

      {
        active: false,
        h: vm.t.q_19_h,
        p: [vm.t.q_19_p_1, vm.t.q_19_p_2, vm.t.q_19_p_3]
      },
      {
        active: false,
        h: vm.t.q_11_h,
        p: [vm.t.q_11_p_1, vm.t.q_11_p_2]
      },
      {
        active: false,
        h: vm.t.q_12_h,
        p: [vm.t.q_12_p_1, vm.t.q_12_p_2]
      },
      {
        active: false,
        h: vm.t.q_13_h,
        p: [vm.t.q_13_p_1]
      },
      {
        active: false,
        h: vm.t.q_14_h,
        p: [vm.t.q_14_p_1]
      },
      {
        active: false,
        h: vm.t.q_15_h,
        p: [vm.t.q_15_p_1, vm.t.q_15_p_2]
      },
      {
        active: false,
        h: vm.t.q_16_h,
        p: [vm.t.q_16_p_1, vm.t.q_16_p_2, vm.t.q_16_p_3]
      }
    ];
  },
  methods: {
    accToggle(e, d) {
      d.active = !d.active;

      function findAncestor(el, sel) {
        while (
          (el = el.parentElement) &&
          !(el.matches || el.matchesSelector).call(el, sel)
        );
        return el;
      }
      const acc_card = findAncestor(e.target, ".acc_card");
      const acc_body = acc_card.querySelector(".acc_body");
      const acc_body_content = acc_card.querySelector(".acc_body_content");

      if (d.active)
        acc_body.style["max-height"] =
          acc_body_content.getBoundingClientRect().height + 100 + "px";
      else acc_body.style["max-height"] = 0 + "px";
    }
  }
};
</script>

<style scoped>
.root_cont {
}

.t_cont {
  padding-top: 250px;
  width: 100%;
  text-align: center;
  color: #fffff3;
  position: relative;
  z-index: 3;
}

.t_cont .blue_bg {
  background-color: rgba(4, 43, 93, 0.8);
}

.t_text {
  padding-top: 40px;
  padding-bottom: 30px;
  line-height: 10px;
  text-align: center;
}

.t_cont h5 {
  line-height: 1.5;
  font-size: 1.25rem; /* return to bs default */
}

.acc_card {
  border-bottom: solid rgba(4, 43, 93, 0.3) 1px;
}

.acc_head {
  cursor: pointer;
  padding: 20px 0;
}

.side_arrow_cont {
  pointer-events: none;
  position: relative;
}

.side_arrow {
  width: 30px;
  transition: 500ms;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 20px;
  margin: auto;
  height: max-content;
}

.side_arrow.active {
  transform: rotateZ(-180deg);
}

.acc_body {
  overflow-y: hidden;
  height: auto;
  max-height: 0;
  transition: 1000ms;
}

.acc_body.active {
  /*max-height: 1500px; */
}

h5 {
  margin-bottom: 0;
  line-height: 1.2;
  font-size: 1.12rem;
}

button.list_all {
  padding: 20px;
  background-color: transparent;
  border: #fffff3 solid;
  border-radius: 10px;
  color: #fffff3;
}

button.list_all span {
  font-size: 20px;
  font-weight: bolder;
}

.pt-40 {
  padding-top: 40px;
}

@media (max-width: 576px) {
  .t_cont {
    padding-top: 0;
  }

  .t_cont .blue_bg {
    background-color: rgba(4, 43, 93, 1);
  }
}
</style>

<style>
.grid_faq_cont {
  padding: 10px;
}

.grid_faq {
  display: grid;
  grid-template-columns: auto auto;
  grid-gap: 10px;

  width: 600px;
  max-width: 95%;
}

.grid_faq > .grid_1 {
}

.grid_faq > .grid_2 {
  text-align: right;
}

.grid_faq .line_s {
  grid-column: 1 / -1;
  border-bottom: solid rgb(4, 43, 93) 1px;
}

.grid_faq .line_d {
  grid-column: 1 / -1;
  border-bottom: dotted rgb(4, 43, 93) 1px;
}

.faq_fusnotes {
  margin-top: 32px;
}

.faq_fusnotes .help_text {
  font-size: 12px;
  margin-bottom: 5px;
}
</style>
